<template>
  <v-card>
    <v-dialog
      v-model="openDialogDetails"
      width="600px"
    >
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ getAppName(selectedApp) }}
          </h3>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-divider />

        <v-card-text>
          <v-carousel v-model="model">
            <v-carousel-item
              v-for="(image, i) in images"
              :key="i"
            >
              <!-- {{ image }} -->
              <v-img
                :src="image"
                contain
                height="100%"
              >
              </v-img>
              <!-- <v-sheet color="primary" height="100%" tile>
                <v-row class="fill-height" align="center" justify="center">
                  </v-row>
              </v-sheet> -->
              <div class="text-h2">
                Image {{ i + 1 }}
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
        <v-divider class="" />
        <v-card-actions class="justify-end text-end">
          <v-spacer></v-spacer>
          <!-- <v-btn v-if="!enabledApps.includes(selectedApp)" @click="installApp" color="primary">
            <span class="text-subtitle-1 font-weight-regular text--white">Install</span>
          </v-btn>
          <v-btn v-else @click="uninstallApp" color="primary">
            <span class="text-subtitle-1 font-weight-regular text--white">Uninstall</span>
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-title>
      <span class="font-weight-black text-h4 text--white">Manage Apps</span>
    </v-card-title>
    <v-card-subtitle class="mt-1">
      <span class="font-weight-regular text-subtitle-1 text--white">Manage The Apps On Your Account</span>
    </v-card-subtitle>
    <v-card-text>
      <v-card
        class="mb-2"
        elevation="6"
      >
        <v-card-text>
          <span class="font-weight-bold text-h6 text--white"> Installed Apps </span>

          <div v-if="enabledApps.length !== 0">
            <v-row class="mt-2">
              <v-col
                v-for="(enabledApp, i) in enabledApps"
                :key="i"
                class=""
                cols="12"
                lg="4"
                md="6"
                sm="12"
                xs="12"
              >
                <v-card
                  class="hover-grow"
                  elevation="6"
                  height="90%"
                  width="90%"
                >
                  <!-- <div @click="openDetails(enabledApp)">
                    <v-img
                      v-if="enabledApp === 'E-Shop'"
                      src="@/assets/images/misc/e-shop.jpeg"
                      max-height="200px"
                    ></v-img>
                    <v-img
                      v-if="enabledApp === 'E-Form'"
                      src="https://i.imgur.com/Egt3QqQ.jpeg"
                      max-height="200px"
                    ></v-img>
                    <v-img
                      v-if="enabledApp === 'ChatBot'"
                      src="@/assets/images/misc/chatbot.jpeg"
                      max-height="200px"
                    ></v-img>
                    <v-img
                      v-if="enabledApp === 'Wa-Login'"
                      src="@/assets/images/misc/whatsapp-otp-collage.jpeg"
                      max-height="200px"
                    ></v-img>
                  </div> -->

                  <v-card-title>
                    <v-btn
                      fab
                      small
                      color="primary"
                    >
                      <v-icon>
                        {{ getAppIcon(enabledApp) }}
                      </v-icon>
                    </v-btn>
                    <span class="font-weight-medium ms-2 my-auto text-h6 text--white">{{
                      getAppName(enabledApp)
                    }}</span>
                  </v-card-title>

                  <v-card-subtitle>
                    <span
                      :class="$vuetify.theme.isDark ? 'white--text' : 'black--text'"
                      class="font-weight-regular my-auto text-subtitle-1"
                    >
                      {{ getAppDescription(enabledApp) }}
                    </span>
                  </v-card-subtitle>
                  <!-- <pre>{{enabledApp}}</pre> -->

                  <v-card-actions class="mt-8">
                    <v-btn
                      absolute
                      bottom
                      left
                      class="text-capitalize"
                      rounded
                      small
                      color="primary"
                      @click="goToApps(enabledApp)"
                    >
                      <span class="text-subtitle-1 font-weight-regular text--white">Open Apps</span>
                    </v-btn>
                    <v-btn
                      v-if="!enabledApps.includes(enabledApp)"
                      absolute
                      bottom
                      right
                      class="text-capitalize"
                      rounded
                      small
                      color="primary"
                      @click="installApp(enabledApp)"
                    >
                      <span class="text-subtitle-1 font-weight-regular text--white">Install</span>
                    </v-btn>
                    <v-btn
                      v-else
                      absolute
                      bottom
                      right
                      class="text-capitalize"
                      color="primary"
                      rounded
                      small
                      @click="uninstallApp(enabledApp)"
                    >
                      <span class="text-subtitle-1 font-weight-regular text--white">Uninstall</span>
                    </v-btn>

                    <!-- <v-btn
                      @click="openDetails(enabledApp)"
                      absolute
                      bottom
                      right
                      class="text-capitalize"
                      color="primary"
                      rounded
                      small
                    >
                      <span class="text-subtitle-1 font-weight-regular text--white">Install</span>
                    </v-btn> -->
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div
            v-else
            class="mt-2"
          >
            <span class="font-weight-regular text-subtitle-1 text--white"> You don't have any apps. </span>
          </div>
        </v-card-text>
      </v-card>

      <v-card
        class="mb-2"
        elevation="6"
      >
        <v-card-text>
          <span class="font-weight-bold text-h6 text--white"> Not Installed </span>

          <div v-if="apps.length !== 0">
            <v-row class="mt-2">
              <v-col
                v-for="(enabledApp, i) in apps"
                :key="i"
                class=""
                cols="12"
                lg="4"
                md="6"
                sm="12"
                xs="12"
              >
                <v-card
                  elevation="6"
                  class="hover-grow"
                  height="100%"
                  width="95%"
                >
                  <!-- <div @click="openDetails(enabledApp)">
                    <v-img
                      v-if="enabledApp === 'E-Shop'"
                      src="@/assets/images/misc/e-shop.jpeg"
                      max-height="200px"
                    ></v-img>
                    <v-img
                      v-if="enabledApp === 'E-Form'"
                      src="https://i.imgur.com/Egt3QqQ.jpeg"
                      max-height="200px"
                    ></v-img>
                    <v-img
                      v-if="enabledApp === 'ChatBot'"
                      src="@/assets/images/misc/chatbot.jpeg"
                      max-height="200px"
                    ></v-img>
                    <v-img
                      v-if="enabledApp === 'Campaign Tracker'"
                      src="@/assets/images/misc/chatbot.jpeg"
                      max-height="200px"
                    ></v-img>
                    <v-img
                      v-if="enabledApp === 'Wa-Login'"
                      src="@/assets/images/misc/whatsapp-otp-collage.jpeg"
                      max-height="200px"
                    ></v-img>
                  </div> -->

                  <v-card-title>
                    <v-btn
                      fab
                      small
                      color="primary"
                    >
                      <v-icon>
                        {{ getAppIcon(enabledApp) }}
                      </v-icon>
                    </v-btn>
                    <span class="font-weight-medium ms-2 my-auto text-h6 text--white">{{
                      getAppName(enabledApp)
                    }}</span>
                  </v-card-title>

                  <v-card-subtitle>
                    <span
                      :class="$vuetify.theme.isDark ? 'white--text' : 'black--text'"
                      class="font-weight-regular my-auto text-subtitle-1"
                    >
                      {{ getAppDescription(enabledApp) }}
                    </span>
                  </v-card-subtitle>

                  <v-card-actions class="mt-8">
                    <v-btn
                      v-if="!enabledApps.includes(enabledApp)"
                      absolute
                      bottom
                      right
                      class="text-capitalize"
                      rounded
                      small
                      color="primary"
                      @click="installApp(enabledApp)"
                    >
                      <span class="text-subtitle-1 font-weight-regular text--white">Install</span>
                    </v-btn>
                    <v-btn
                      v-else
                      absolute
                      bottom
                      right
                      class="text-capitalize"
                      color="primary"
                      rounded
                      small
                      @click="uninstallApp(enabledApp)"
                    >
                      <span class="text-subtitle-1 font-weight-regular text--white">Uninstall</span>
                    </v-btn>

                    <!-- <v-btn
                      @click="openDetails(enabledApp)"
                      absolute
                      bottom
                      right
                      class="text-capitalize"
                      color="primary"
                      rounded
                      small
                    >
                      <span class="text-subtitle-1 font-weight-regular text--white">Install</span>
                    </v-btn> -->
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div
            v-else
            class="mt-2"
          >
            <span class="font-weight-regular text-subtitle-1 text--white"> All apps have been installed </span>
          </div>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiStore,
  mdiFormSelect,
  mdiRobotExcitedOutline,
  mdiAdvertisements,
  mdiPlusCircleOutline,
  mdiAccountLock,
} from '@mdi/js'

export default {
  name: 'AppsList',
  data() {
    return {
      icons: {
        mdiStore,
        mdiFormSelect,
        mdiRobotExcitedOutline,
        mdiPlusCircleOutline,
        mdiAdvertisements,
        mdiAccountLock,
      },
      images: [],
      openDialogDetails: false,
      selectedApp: '',
      model: 0,
    }
  },
  computed: {
    enabledApps() {
      return this.$store.getters['global/getEnabledApps']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    apps() {
      const appList = ['E-Shop', 'E-Form', 'ChatBot', 'Campaign Tracker', 'Wa-Login']

      if (this.enabledApps.length !== 0) {
        return appList.filter(app => !this.enabledApps.includes(app))
      }

      return appList
    },
  },
  async mounted() {
    const enabledApps = await this.$store.dispatch('global/getSettingsByKey', {
      sub_id: this.user.sub_id,
      token: this.user.token,
      key: 'enabled_apps',
    })

    if (enabledApps.status && enabledApps.data) {
      // //console.log(enabledApps.data)
      this.$store.commit('global/setEnabledApps', enabledApps.data.value)

      // this.$store.commit()
    }
  },
  methods: {
       goToApps(item) {
        // 'E-Shop', 'E-Form', 'ChatBot', 'Campaign Tracker', 'Wa-Login'
        if(item == 'E-Shop' ){
          this.$router.push({ name: 'e-ordering' })
        }else if(item == "E-Form"){
          this.$router.push({ name: 'e-form'})
        }else if(item == "ChatBot"){
          this.$router.push({ name: 'chatbot'})
        }else if(item == "Campaign Tracker"){
          this.$router.push({ name: 'campaign-tracker'})
        }else if(item == "Wa-Login"){
          this.$router.push({ name: 'LoginByWa'})
        }
      // let stilAble = this.listContactValue.find(el => {
      //   return el.id === item.id
      // })

      // if (stilAble) {
      //   if (item.deleteable) {
      // //console.log(item, `INI ITEM`)



      //   } else {
      //     this.$router.push('contacts-list')
      //   }
      // } else {
      //   this.$router.push({ name: 'contacts-group-list' })
      // }
    },
    async uninstallApp(selectedApp) {
      let currentApp = JSON.parse(JSON.stringify(this.enabledApps))
      currentApp = currentApp.filter(app => app !== selectedApp)

      // //console.log('this.selectedApp: ', this.selectedApp)
      // //console.log('currentApp: ', currentApp)
      await this.$store.dispatch('global/updateSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'enabled_apps',
        value: currentApp,
      })
      this.$store.commit('global/setEnabledApps', currentApp)
      this.openDialogDetails = false
      this.selectedApp = ''
    },
    async installApp(selectedApp) {
      const currentApp = JSON.parse(JSON.stringify(this.enabledApps))
      currentApp.push(selectedApp)

      // //console.log('currentApp: ', currentApp)
      await this.$store.dispatch('global/updateSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'enabled_apps',
        value: currentApp,
      })
      this.$store.commit('global/setEnabledApps', currentApp)
      this.openDialogDetails = false
      this.selectedApp = ''
    },
    getImages(app) {
      if (app === 'E-Shop') {
        return '@/assets/images/misc/e-shop.jpeg'
      }

      if (app === 'E-Form') {
        return '@/assets/images/misc/e-shop.jpeg'
      }

      if (app === 'Wa-Login') {
        return '@/assets/images/misc/whatsapp-otp-collage.jpeg'
      }

      return '@/assets/images/misc/chatbot.jpeg'
    },
    openDetails(app) {
      // //console.log(app)
      this.images = []
      this.openDialogDetails = true

      // this.selectedApp = app
      // this.images = ['/sendcheap/src/assets/images/misc/e-shop.jpeg']
      if (app === 'E-Shop') {
        this.images = [
          'https://i.ibb.co/r7MDt5g/4.jpg',
          'https://i.ibb.co/YcTCBz0/3.jpg',
          'https://i.ibb.co/K62zv85/2.jpg',
          'https://i.ibb.co/svD9tGs/1.jpg',
        ]
      } else if (app === 'E-Form') {
        this.images = [
          'https://i.ibb.co/tDN9Ykh/Whats-App-Image-2022-05-27-at-1-47-24-PM-3.jpg',
          'https://i.ibb.co/bBbXfSx/Whats-App-Image-2022-05-27-at-1-47-24-PM-2.jpg',
          'https://i.ibb.co/3vtPCZT/Whats-App-Image-2022-05-27-at-1-47-24-PM-1.jpg',
          'https://i.ibb.co/T2t6FS4/Whats-App-Image-2022-05-27-at-1-47-24-PM.jpg',
        ]
      } else if (app === 'Wa-Login') {
        this.images = [
          'https://i.imgur.com/hcsue5n.png',
          'https://i.imgur.com/YoC9dK9.png',
          'https://i.imgur.com/ZrbbVWB.png',
        ]
      } else {
        this.images = [
          'https://i.ibb.co/g79fhZN/7.jpg',
          'https://i.ibb.co/fxqg74j/6.jpg',
          'https://i.ibb.co/7K6GNyY/5.jpg',
        ]
      }
    },
    getAppDescription(app) {
      if (app === 'E-Shop') {
        return `${this.$t('EOrdering.hero.moreSales')}. ${this.$t('EOrdering.hero.moreSales')}. ${this.$t(
          'EOrdering.hero.isByAdding',
        )}  ${this.$t('EOrdering.hero.here')}.`
      }
      if (app === 'E-Form') {
        return `${this.$t('eform.notFoundAction')} ${this.$t('eform.notFoundAction2')}`
      }
      if (app === 'Campaign Tracker') {
        return `${this.$t('campaignTracker.description')}`
      }
      if (app === 'Wa-Login') {
        return `${this.$t('waLogin.description')}`
      }

      return `Boost your customer support efforts with intelligent routing and dialog flows. Chatbot is of course, very convenient.`
    },
    getAppIcon(app) {
      if (app === 'E-Shop') {
        return this.icons.mdiStore
      }
      if (app === 'E-Form') {
        return this.icons.mdiFormSelect
      }
      if (app === 'Campaign Tracker') {
        return this.icons.mdiAdvertisements
      }
      if (app === 'Wa-Login') {
        return this.icons.mdiAccountLock
      }

      return this.icons.mdiRobotExcitedOutline
    },
    getAppName(app) {
      if (app === 'E-Shop') {
        return 'E-Shop'
      }
      if (app === 'E-Form') {
        return 'E-Form'
      }
      if (app === 'Campaign Tracker') {
        return 'Campaign Tracker'
      }
      if (app === 'Wa-Login') {
        return 'Login by Whatsapp'
      }

      return 'ChatBot'
    },
  },
}
</script>

<style scoped>
.hover-grow {
  cursor: pointer;
  /* transition: all 0.2s ease-in-out; */
}

.hover-grow:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.1);
}
</style>
